<template>
  <div class="animated fadeIn">
    <b-card header="New Product">
      <b-form @submit.prevent="saveData" @reset="GoBack">
        <picture-input
          prefill="http://www.pandata.online/public/images/products/2.png"
          ref="imageInput"
          @change="onChange"
          width="300"
          height="300"
          margin="16"
          accept="image/jpeg, image/png"
          size="10"
          buttonClass="btn btn-primary button"
          :removable="true"
          :customStrings="{
            upload: '<h1>Bummer!</h1>',
            drag: 'Click here to upload picture'
          }"
        ></picture-input>

        <!-- <img :src="image" />
        <input type="file" @change="onChange" accept="image/*" /> -->

        <table class="table-p">
          <tr>
            <td>{{ $t("message.machineType") }}</td>
            <td>
              <b-form-select
                v-model="newProduct.type"
                :options="machineType"
                text-field="name"
                value-field="name"
              />
            </td>
          </tr>
          <tr>
            <td>{{ $t("message.productType") }}</td>
            <td>
              <b-form-select
                v-model="newProduct.productType"
                :options="productType"
                text-field="name"
                value-field="value"
              />
            </td>
          </tr>
          <tr>
            <td>{{ $t("message.sku") }}</td>
            <td>
              <b-form-input v-model="newProduct.sku" required />
            </td>
          </tr>
          <tr>
            <td>{{ $t("message.productName") }}</td>
            <td>
              <b-form-input v-model="newProduct.name" required />
            </td>
          </tr>
          <template
            v-if="
              newProduct.productType === 'washer' ||
              newProduct.productType === 'dryer'
            "
          >
            <tr>
              <td>{{ $t("message.tmpOrTime") }}</td>
              <td>
                <b-form-input
                  type="number"
                  v-model.number="newProduct.details.value"
                />
              </td>
            </tr>
          </template>
          <!-- <tr>
            <td>{{ $t("message.price") }}</td>
            <td>
              <b-form-input v-model.number="newProduct.cost" required/>
            </td>
          </tr>
          <tr>
            <td>{{ $t("message.cost") }}</td>
            <td>
              <b-form-input v-model.number="newProduct.stdPrice" required/>
            </td>
          </tr> -->
          <tr>
            <td>{{ $t("message.description") }}</td>
            <td>
              <b-form-input v-model="newProduct.description" required />
            </td>
          </tr>
          <!-- <tr>
            <td>{{ $t("message.wide") }}</td>
            <td>
              <b-form-input v-model.number="newProduct.width" required/>
            </td>
          </tr>
          <tr>
            <td>{{ $t("message.high") }}</td>
            <td>
              <b-form-input v-model.number="newProduct.height" required/>
            </td>
          </tr> -->
        </table>
        <div align="right">
          <b-button
            type="reset"
            @click="GoBack()"
            variant="danger"
            :active="true"
            size="lg"
            >{{ $t("message.back") }}</b-button
          >
          <b-button type="submit " variant="success" :active="true" size="lg">{{
            $t("message.save")
          }}</b-button>
        </div>
      </b-form>
    </b-card>
    <!--
      <div>
        <b-card header="Add Categories">
          <b-form-select v-model="selected" :options="options" class="mb-3" />
          <div>Selected: <strong>{{ selected }}</strong></div>
          <b-button variant="primary" :active="true">Category 1</b-button>
          <b-button variant="primary" :active="true">Category 2</b-button> 
        </b-card>
      </div>
    -->
  </div>
</template>

<script>
import PictureInput from "vue-picture-input";
import webServices from "../../script";
export default {
  name: "NewProduct",
  components: {
    PictureInput,
  },
  data() {
    return {
      id: this.$route.params.productid,
      mode: this.$route.params.mode,
      newProduct: {
        type: "vending",
        details: {},
        cost: 0,
        stdPrice: 0,
        width: 0,
        height: 0,
      },
      image: "",
      imageData: "",
      machineType: {},
      productType: [
        {
          name: "น้ำกระป๋อง",
          value: "can",
        },
        {
          name: "ขนม",
          value: "snack",
        },
        {
          name: "เครื่องซักผ้า",
          value: "washer",
        },
        {
          name: "เครื่องอบผ้า",
          value: "dryer",
        },
        {
          name: "น้ำยาปรับผ้านุ่ม",
          value: "softener",
        },
        {
          name: "น้ำยาซักผ้า",
          value: "detergent",
        },
      ],
    };
  },
  mounted() {
    this.getMachineType();
    if (this.mode === "create") {
    } else if (this.mode === "edit") {
      webServices
        .getProductId(this.id)
        .then((res) => {
          // console.log(res.data)
          this.newProduct = res.data;
          // console.log(this.newProduct.image);
          if (this.newProduct.image !== null) {
            this.image = "http://www.pandata.online/" + res.data.image;
            // console.log(this.image);
            // this.image = 'http://localhost:8888/' + res.data.image
          } else {
            this.image = "";
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      console.log("error mode");
    }
  },
  methods: {
    GoBack() {
      this.$router.push("/product/products");
    },
    getMachineType() {
      this.$Progress.start();
      webServices
        .getMachineType()
        .then((res) => {
          this.$Progress.finish();
          this.machineType = res.data;
        })
        .catch((err) => {
          this.$Progress.fail();
          console.log("ERROR @MACHINE TYPE");
          if (err.response.status === 401) {
            webServices.tokenExpire();
          }
          this.$toast.error({
            title: "ERROR",
            message: err,
          });
          console.log(err);
        });
    },
    onChange(img) {
      // var input = event.target;
      // // Ensure that you have a file before attempting to read it
      // if (input.files && input.files[0]) {
      //   // create a new FileReader to read this image and convert to base64 format
      //   var reader = new FileReader();
      //   // Define a callback function to run, when FileReader finishes its job
      //   reader.onload = (e) => {
      //     // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
      //     // Read image as base64 and set to imageData
      //     this.imageData = e.target.result;
      //   };
      //   // Start the reader job - read file as a data url (base64 format)
      //   reader.readAsDataURL(input.files[0]);
      //   webServices
      //     .uploadImage(this.imageData)
      //     .then((res) => {
      //       console.log(res.data);
      //       this.newProduct.image = res.data.filename;
      //     })
      //     .catch((err) => {
      //       console.log("ไม่สามารถอัพโหลดรูปไปที่เซิฟเวอร์ได้");
      //       const errorText = {
      //         error: err.response.data,
      //         code: err.response.status,
      //         text: err.response.statusText,
      //       };
      //       this.$toast.error({
      //         title: "ERROR",
      //         message: JSON.stringify(errorText),
      //       });
      //       console.log(err);
      //     });
      // }
      if (this.$refs.imageInput.image) {
        var image = this.$refs.imageInput.file;
        const formData = new FormData();
        formData.append("image", image);
        webServices
          .uploadImage(formData)
          .then((res) => {
            this.newProduct.image = res.data.filename;
          })
          .catch((err) => {
            console.log("ไม่สามารถอัพโหลดรูปไปที่เซิฟเวอร์ได้");
            const errorText = {
              error: err.response.data,
              code: err.response.status,
              text: err.response.statusText,
            };
            this.$toast.error({
              title: "ERROR",
              message: JSON.stringify(errorText),
            });
            console.log(err);
          });
      } else {
        console.log("ไม่สามารถอัพโหลดรูปได้");
      }
    },
    saveData() {
      // console.log(this.mode)
      delete this.newProduct.createdAt;
      delete this.newProduct.updatedAt;
      console.log(this.newProduct);
      if (this.mode === "create") {
        webServices
          .createProduct(this.newProduct)
          .then((res) => {
            console.log(res.data);
            // this.newProduct = ''
            if (res.data.success) {
              this.$toast.success({
                title: "SUCCESS",
                message: "New product has been created",
              });
              this.$router.push("/product/products");
            } else {
              const errorText = {
                errorCode: res.data.errorCode,
                errorText: res.data.errorText,
              };
              this.$toast.error({
                title: "ERROR",
                message: JSON.stringify(errorText),
              });
            }
          })
          .catch((err) => {
            const errorText = {
              error: err.response.data,
              code: err.response.status,
              text: err.response.statusText,
            };
            this.$toast.error({
              title: "ERROR",
              message: JSON.stringify(errorText),
            });
            console.log(err);
          });
      } else if (this.mode === "edit") {
        webServices
          .updateProduct(this.newProduct, this.id)
          .then((res) => {
            console.log(res.data);
            this.newProduct = "";
            if (res.data.success) {
              this.$toast.success({
                title: "SUCCESS",
                message: "product has been updated",
              });
              this.$router.push("/product/products");
            } else {
              const errorText = {
                errorCode: res.data.errorCode,
                errorText: res.data.errorText,
              };
              this.$toast.error({
                title: "ERROR",
                message: JSON.stringify(errorText),
              });
            }
          })
          .catch((err) => {
            const errorText = {
              error: err.response.data,
              code: err.response.status,
              text: err.response.statusText,
            };
            this.$toast.error({
              title: "ERROR",
              message: JSON.stringify(errorText),
            });
            console.log(err);
          });
      }
    },
  },
};
</script>

<style lang="scss">
.table-p {
  width: 50%;
  border: none;
  margin: 0 auto;
}
.table-p td,
.table-p th {
  padding: 8px;
}
.table td {
  margin: 0;
}
</style>